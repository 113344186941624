import React from 'react';

import Retail from 'components/Menu/Retail';
import Jumbotron from 'components/misc/Jumbotron';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '베이커리', link: '/menu/bakery/' },
  { name: '음료', link: '/menu/beverage/' },
  { name: '디저트', link: '/menu/dessert/' },
  { name: '푸드', link: '/menu/food/' },
  { name: '상품', link: '/menu/retail/' },
];

const RetailPage = () => (
  <div>
    <Jumbotron
      engTitle="Retail"
      korTitle="상품"
      image={require('../../assets/images/banner/menu-retail.jpg')}
      content={
        <span>
          특별한 선물을 원하시는 고객들을 위해
          <br />
          매년 시즌별로 다양한 제품들을 출시하고 있습니다.
          <br />
          아티제만의 감성적인 리테일 제품들을 만나보세요.
          <br />
          <br />
        </span>
      }
    />
    <TabBar index={4} data={tabBarMenus} />
    <Retail />
  </div>
);

export default RetailPage;
