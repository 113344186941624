export default {
  teddyBears: [
    {
      id: '001',
      korName: '테디베어 그랑',
      engName: 'teddy bear grande',
      imageUri: require('../../assets/images/menu/retail/teddy_bear/001.png'),
      price: 26000,
    },
    {
      id: '002',
      korName: '테디베어 쁘띠',
      engName: 'teddy bear petit',
      imageUri: require('../../assets/images/menu/retail/teddy_bear/002.png'),
      price: 17000,
    },
    {
      id: '003',
      korName: '테디베어 베베',
      engName: 'teddy bear bebe',
      imageUri: require('../../assets/images/menu/retail/teddy_bear/003.png'),
      price: 11000,
    },
  ],
  tumblers: [
    {
      id: '001',
      korName: '킨토 텀블러 (DAY OFF)',
      engName: 'kinto tumbler DAY OFF',
      imageUri: require('../../assets/images/menu/retail/tumbler/001.png'),
      price: 45000,
    },
    {
      id: '002',
      korName: '킨토 텀블러 (DAY OFF)',
      engName: 'kinto tumbler DAY OFF',
      imageUri: require('../../assets/images/menu/retail/tumbler/002.png'),
      price: 45000,
    },
    {
      id: '003',
      korName: '킨토 텀블러 (DAY OFF)',
      engName: 'kinto tumbler DAY OFF',
      imageUri: require('../../assets/images/menu/retail/tumbler/003.png'),
      price: 45000,
    },
    {
      id: '004',
      korName: '아티제 머그컵 12oz',
      engName: 'artisee mug 12oz',
      imageUri: require('../../assets/images/menu/retail/tumbler/004.png'),
      price: 11500,
    },
    {
      id: '005',
      korName: '아티제 머그컵 16oz',
      engName: 'artisee mug 16oz',
      imageUri: require('../../assets/images/menu/retail/tumbler/005.png'),
      price: 13500,
    },
    {
      id: '006',
      korName: '아티제 베이직 텀블러(베이지)',
      engName: 'artisee basic tumbler (Beige)',
      imageUri: require('../../assets/images/menu/retail/tumbler/006.png'),
      price: 24000,
    },
    {
      id: '007',
      korName: '아티제 베이직 텀블러(브라운)',
      engName: 'artisee basic tumbler (Brown)',
      imageUri: require('../../assets/images/menu/retail/tumbler/007.png'),
      price: 24000,
    },
  ],
  products: [
    {
      id: '001',
      korName: '엘글라스 머그 (클리어)',
      engName: 'L glass mug (clear)',
      imageUri: require('../../assets/images/menu/retail/product/001.png'),
      price: 9900,
    },
    {
      id: '002',
      korName: '엘글라스 머그 (그레이)',
      engName: 'L glass mug (gray)',
      imageUri: require('../../assets/images/menu/retail/product/002.png'),
      price: 9900,
    },
    {
      id: '003',
      korName: '엘글라스 하이볼 (클리어)',
      engName: 'L glass highball (clear)',
      imageUri: require('../../assets/images/menu/retail/product/003.png'),
      price: 9900,
    },
    {
      id: '004',
      korName: '엘글라스 하이볼 (그레이)',
      engName: 'L glass highball (gray)',
      imageUri: require('../../assets/images/menu/retail/product/004.png'),
      price: 9900,
    },
  ],
};
