import React from 'react';
import classNames from 'classnames/bind';
import Swiper from 'react-id-swiper';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import ContainerDimensions from 'react-container-dimensions';

import styles from './Retail.module.scss';
import MenuItem from './common/MenuItem';
import MenuSetLeft from './common/MenuSetLeft';
import MenuSetRight from './common/MenuSetRight';
import Divider from './common/Divider';
import menus from '../../datas/salemenu/retail.js';
import { params3, params4, params5 } from './common/swiperParam';

const cx = classNames.bind(styles);
const { teddyBears, tumblers, products } = menus;

const Retail = () => (
  <div>
    {/* 테디 베어 */}
    <MenuSetLeft
      engTitle="Teddy bear"
      korTitle="테디 베어"
      image={require('../../assets/images/menu/retail/teddy_bear/big.png')}
      content={
        <span>
          특별한 날에 감동을 더해주는,
          <br />
          100% 수작업으로 만드는 아티제만의 테디베어
        </span>
      }>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip1', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetLeft>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12} md={9} lg={7.2} offset={{ md: 1.5, lg: 2.4 }}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params3}>
                    {teddyBears.map((teddyBear, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItem item={teddyBear} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 텀블러 & 머그 */}
    <MenuSetRight
      engTitle="Tumbler & Mug"
      korTitle="텀블러 & 머그"
      image={require('../../assets/images/menu/retail/tumbler/big.png')}
      content={<span>고스란히 전해지는 아티제 감성에 실용성을 더한 텀블러 & 머그</span>}>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip2', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetRight>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params5}>
                    {tumblers.map((tumbler, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItem item={tumbler} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>

    <Divider />

    {/* 일러스트 상품 */}
    <MenuSetLeft
      engTitle="Illustration products"
      korTitle="일러스트 상품"
      image={require('../../assets/images/menu/retail/product/big.png')}
      content={
        <span>
          특별한 선물이 필요할 때,
          <br />
          아티제만의 일러스트레이션이 담긴
          <br />
          다양한 시즌 상품
        </span>
      }>
      <ScreenClassRender
        render={screenClass => (
          <div className={cx(['illust', 'clip3', { mobile: ['xs', 'sm', 'md'].includes(screenClass) }])} />
        )}
      />
    </MenuSetLeft>
    <div className={cx('swiper')}>
      <Container fluid>
        <Row>
          <Col xs={12} lg={9.6} offset={{ lg: 1.2 }}>
            <ContainerDimensions>
              {({ width }) => {
                return (
                  <Swiper {...params4}>
                    {products.map((product, i) => {
                      return (
                        <div key={i} className={cx('item')}>
                          <MenuItem item={product} />
                        </div>
                      );
                    })}
                  </Swiper>
                );
              }}
            </ContainerDimensions>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default Retail;
