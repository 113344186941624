import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';

import styles from './MenuItem.module.scss';

const cx = classNames.bind(styles);

const MenuItem = ({ item }) => {
  const { korName, engName, imageUri, price } = item;

  return (
    <div className={cx('item')}>
      {/* 이미지 */}
      <img src={imageUri} alt={korName} width="100%" />

      {/* 내용 */}
      <div className={cx('info')}>
        <div className={cx('detail')}></div>
        <div className={cx('title')}>
          <div className={cx('korName')}>{korName}</div>
          <div className={cx('engName')}>{engName}</div>
        </div>
        <div className={cx('price')}>{commaNumber(price)}</div>
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default MenuItem;
